import React from "react"

import Layout from "../../components/Layout"
import RibbonText from "../../components/ui/RibbonText"
import Contact from "../../components/form/Contact"
import Seo from "../../components/Seo"

export default function Page() {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <section className="wrapper container py-8 md:py-16 grid grid-cols-1 md:grid-cols-2 gap-x-0 gap-y-4 md:gap-y-0 md:gap-x-10 lg:gap-x-20 h-full">
        <div className="flex flex-col lg:h-full">
          <h2>
            To start a conversation about your next project, please call or send
            us and email and we’ll be in touch shortly.
          </h2>
          <div className="flex flex-col space-y-2 md:space-y-5 my-4 md:mt-5">
            <div className="flex flex-row space-x-2">
              <h4>Email:</h4>
              <p>admin@builderfinders.com.au</p>
            </div>
            <div className="flex flex-row space-x-2">
              <h4>Phone:</h4>
              <p>1300 4 A BUILDER (1300 422 845)</p>
            </div>
            <div className="flex flex-row space-x-2">
              <h4>Address:</h4>
              <p>PO Box 4715 Sylvania Waters NSW 2224</p>
            </div>
          </div>
          <RibbonText
            text="Get in touch"
            highlighted="today"
            justify="left"
            className="mt-auto"
          />
        </div>
        <div>
          <Contact />
        </div>
      </section>
    </Layout>
  )
}
