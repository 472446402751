import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import TextInput from "./TextInput"
import TextAreaInput from "./TextAreaInput"

const portalId = "5684517"
const formId = "3bc5cfaa-f74e-4093-8043-9fa87c7c47f4"
const hubspotUrl = "https://api.hsforms.com/submissions/v3/integration/submit/" + portalId + "/" + formId

export default function Contact(props) {
  const [submitted, setSubmitted] = useState(false)
  const sleep = ms => new Promise(r => setTimeout(r, ms))

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        subject: "",
        message: "",
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required("Your first name is required."),
        lastname: Yup.string().required("Your last name is required."),
        email: Yup.string()
          .email("Invalid email address.")
          .required("Your email is required."),
        subject: Yup.string().required("Please complete this required field."),
        message: Yup.string().required("Please complete this required field."),
      })}
      onSubmit={async (values, { resetForm }) => {
        await sleep(500)
        const finalValues = []

        for (const value in values) {
          if (Array.isArray(values[value])) {
            finalValues.push({
              name: value,
              value: values[value].join(";"),
            })
          } else {
            finalValues.push({
              name: value,
              value: values[value],
            })
          }
        }

        await fetch(hubspotUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "fields": finalValues
          })
        })

        resetForm()
        setSubmitted(true)
        // console.log("Submitted Form", values)
      }}
    >
      {({ isSubmitting }) =>
        submitted ? (
          <div className="flex flex-col items-center mt-8">
            <p className="y-16 py-2 px-32 uppercase font-bold text-sm tracking-widest bg-primary text-white mx-auto text-center">
              Success! We'll be in touch with you as soon as possible.
            </p>
          </div>
        ) : (
          <Form className="flex flex-col">
            <div className="space-y-2 sm:space-y-4">
              <h3>Contact</h3>
              <div className="grid lg:grid-cols-2 gap-2 sm:gap-4 lg:gap-10">
                <TextInput
                  label="First Name"
                  name="firstname"
                  type="text"
                  className="flex flex-col"
                />
                <TextInput
                  label="Last Name"
                  name="lastname"
                  type="text"
                  className="flex flex-col"
                />
              </div>
              <TextInput
                label="Email"
                name="email"
                type="email"
                className="flex flex-col"
              />
              <TextInput
                label="Subject"
                name="subject"
                type="text"
                className="flex flex-col"
              />
              <TextAreaInput
                label="Message"
                name="message"
                className="flex flex-col"
              />
            </div>
            <button
              type="submit"
              className="mt-8 sm:mt-16 py-2 px-32 uppercase font-bold text-sm tracking-widest bg-primary text-white"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting" : "Submit"}
            </button>
          </Form>
        )
      }
    </Formik>
  )
}
